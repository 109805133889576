// RatingPage.jsx
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import TabsNavBar from '#/components/navigation/TabsNavBar'
import styles from './RatingPage.module.css'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'
import diamond from '#/assets/diamond.gif'
import { FiltersIcon } from '#/assets/icons'
import API from '#/api/api'
import TelegramAPI from '#/telegram'
import UserCard from './components/UserCard'

const tabs = [
  { name: 'day', title: 'Day' },
  { name: 'week', title: 'Week' },
]

export default function RatingPage() {
  const [rating, setRating] = useState([])
  const [filter, setFilter] = useState('balance')
  const [activeTab, setActiveTab] = useState('day')
  const [loading, setLoading] = useState(false)

  const fetchTop = useCallback(async (currentFilter, ratingType) => {
    setLoading(true)
    try {
      const users = await API.user.getUserTop(currentFilter, ratingType)
      setRating(users)
    } catch (error) {
      console.error('Failed to fetch top users:', error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const ratingType = activeTab === 'day' ? 'daily' : 'weekly'
    fetchTop(filter, ratingType)
  }, [filter, activeTab, fetchTop])

  const handleClickFilter = useCallback(() => {
    TelegramAPI.Vibrate('heavy')
    setFilter((prevFilter) => (prevFilter === 'balance' ? 'ghs' : 'balance'))
  }, [])

  const renderedUsers = useMemo(() => {
    return rating.map((user, index) => <UserCard key={user.id || index} item={user} filter={filter} />)
  }, [rating, filter])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={diamond} className={styles.gif} alt="Diamond" loading="lazy" />
        <div className={styles.pageHeader}>Top miners</div>
      </div>

      <TabsNavBar activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />

      <div className={styles.filtersButton} onClick={handleClickFilter}>
        <FiltersIcon additionalColor="#0097EC" /> {filter === 'balance' ? 'Balance' : 'TH/s'}
      </div>

      <div className={styles.usersTable}>{renderedUsers}</div>

      <BottomNavigation activeTab="rating" />
    </div>
  )
}
