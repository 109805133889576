import React, { createContext, useContext, useEffect, useState, useRef } from 'react'
import API from '../api/api'
import TelegramAPI from '../telegram'
import useNotification from '#/hooks/useNotification'
import Notification from '#/components/alerts/Notification'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [rewardsInfo, setRewardsInfo] = useState([])
  const [tasks, setTasks] = useState([])
  const [cards, setCards] = useState([])
  const [boosters, setBoosters] = useState([])
  const [error, setError] = useState(false)
  const [groupedCards, setGroupedCards] = useState([])
  const [settings, setSettings] = useState([])
  const [withdraws, setWithdrawhs] = useState([])
  const [timerMilis, setTimerMilis] = useState(0)
  const [storedDeposits, setStoredDeposits] = useState([])
  const { notification, isVisible, showNotification } = useNotification()

  const timerInterval = useRef(null)

  const updateUserInfo = async () => {
    const response = await API.user.getUserInfo()
    if (response) {
      setUser(response)
      setRewardsInfo(response.miningInfo?.rewardsInfo || [])
      setTimerMilis(response.miningInfo?.timerMilis || 0)
    } else {
      setError(true)
    }
  }

  // Функция для форматирования времени в часы:минуты:секунды
  const formatTime = (ms) => {
    let totalSeconds = Math.floor(ms / 1000)
    let hours = Math.floor(totalSeconds / 3600)
    let minutes = Math.floor((totalSeconds % 3600) / 60)
    let seconds = totalSeconds % 60

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  // Функция для получения форматированного времени таймера
  const getFormattedTimer = () => formatTime(timerMilis)

  useEffect(() => {
    // Очищаем предыдущий интервал, если он существует
    if (timerInterval.current) {
      clearInterval(timerInterval.current)
    }

    // Запускаем новый интервал, если таймер больше нуля
    if (timerMilis > 0) {
      timerInterval.current = setInterval(() => {
        setTimerMilis((prevTimer) => {
          if (prevTimer > 1000) {
            return prevTimer - 1000
          } else {
            clearInterval(timerInterval.current)
            return 0
          }
        })
      }, 1000)
    }

    return () => clearInterval(timerInterval.current)
  }, [timerMilis])

  // Рассчитать награду в секунду
  const calculateRewardPerSecond = (coinsPerHour) => {
    return coinsPerHour / 3600
  }

  useEffect(() => {
    if (rewardsInfo.length > 0) {
      groupCardsByCount()

      if (user?.miningInfo?.miningActive) {
        const interval = setInterval(() => {
          setRewardsInfo((prevRewards) =>
            prevRewards.map((card) => ({
              ...card,
              reward: (parseFloat(card.reward) + calculateRewardPerSecond(card.coinsPerHour)).toFixed(8),
            }))
          )
        }, 1000)

        return () => clearInterval(interval)
      }

      return
    }
  }, [rewardsInfo])

  // Группировка карт по количеству
  const groupCardsByCount = () => {
    const grouped = {}
    rewardsInfo.forEach((card) => {
      if (!grouped[card.videocardId]) {
        grouped[card.videocardId] = {
          ...card,
          count: 1,
          reward: parseFloat(card.reward),
        }
      } else {
        grouped[card.videocardId].count += 1
        grouped[card.videocardId].reward += parseFloat(card.reward)
      }
    })
    setGroupedCards(Object.values(grouped))
  }

  // Tasks
  const getTasks = async () => {
    if (user) {
      const result = await API.tasks.getTasks()
      if (result) {
        setTasks(result.data)
      }
    }
  }

  const completeTask = async (task_id) => {
    try {
      const result = await API.tasks.completeTask(task_id)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const verifyTask = async (task_id) => {
    try {
      const result = await API.tasks.verifyTaskCompletion(task_id)
      return result
    } catch (e) {
      console.log(e)
    }
  }

  // Mining
  const claimMiningRewards = async () => {
    try {
      const result = await API.mining.claimReward()
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const continueMining = async () => {
    try {
      const result = await API.mining.continueMining()
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  // Shop
  const getShopCards = async () => {
    try {
      const result = await API.shop.getAviableVideocards()
      setCards(result)
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const getShopBoosters = async () => {
    try {
      if (user) {
        const result = await API.shop.getAviableBoosters()
        const purchasedBoosterIds = user.boosters.map((booster) => booster.item_id)
        const updatedBoosters = result.map((booster) => ({
          ...booster,
          buyed: purchasedBoosterIds.includes(booster.id),
        }))

        setBoosters(updatedBoosters)
        return updatedBoosters
      }
      return
    } catch (e) {
      console.log(e)
    }
  }

  const buyBooster = async (item_id, payment = 'coin', wallet = '') => {
    try {
      const result = await API.shop.buyBooster(item_id, payment, wallet)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const buyVideocard = async (item_id, count, payment = 'coin', wallet = '') => {
    try {
      const result = await API.shop.buyVideocard(item_id, count, payment, wallet)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const getSettings = async () => {
    try {
      const response = await API.system.getSettings()
      if (response.success) {
        setSettings(response.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const withdraw = async (value, wallet) => {
    try {
      const response = await API.user.withdraw(value, wallet)
      if (response.success) {
        getWithdrawHistory()
        updateUserInfo()
      }
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const getWithdrawHistory = async () => {
    try {
      const response = await API.user.withdraws()
      if (response.success) {
        setWithdrawhs(response.data)
      }
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const checkTransactionAfterDeposit = async (deposits) => {
    try {
      if (deposits) {
        setStoredDeposits(deposits) // Обновляем состояние
      }

      const result = await API.deposit.getDepositHistory()

      if (result && result.success) {
        setStoredDeposits((prevDeposits) => {
          const newDeposits = result.deposits.filter(
            (deposit) => !prevDeposits.some((existingDeposit) => existingDeposit.deposit_id === deposit.deposit_id)
          )

          if (newDeposits.length > 0) {
            updateUserInfo()
            showNotification('success', 'Balance successfully topped up', 3000)
            return [...prevDeposits, ...newDeposits] // Возвращаем обновлённое состояние
          }

          return prevDeposits // Если нет новых депозитов, возвращаем текущее состояние
        })

        return true
      } else {
        // Если запрос не успешен, ждем 10 секунд и повторяем
        await new Promise((resolve) => setTimeout(resolve, 10000))
        return checkTransactionAfterDeposit() // Рекурсивный вызов
      }
    } catch (e) {
      console.log('Error during deposit check:', e)
    }
  }

  useEffect(() => {
    updateUserInfo()
  }, [])

  useEffect(() => {
    if (user) {
      getShopBoosters()
      getShopCards()
      getSettings()
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        getTasks,
        verifyTask,
        completeTask,
        claimMiningRewards,
        getShopBoosters,
        getShopCards,
        tasks,
        cards,
        boosters,
        buyBooster,
        buyVideocard,
        updateUserInfo,
        continueMining,
        error,
        rewardsInfo,
        groupedCards,
        settings,
        getWithdrawHistory,
        withdraws,
        withdraw,
        timerMilis,
        getFormattedTimer,
        checkTransactionAfterDeposit,
        storedDeposits,
        setStoredDeposits,
      }}>
      <>
        {children}
        <Notification notification={notification} isVisible={isVisible} />
      </>
    </UserContext.Provider>
  )
}

export const useUser = () => {
  return useContext(UserContext)
}
