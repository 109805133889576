import React, { useEffect, useState, useCallback, useMemo } from 'react'
import TelegramAPI from '#/telegram'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'
import Slider from './components/Slider/Slider'
import { BalanceCoinIcon } from '#/assets/icons'
import { useUser } from '#/context/userContext'
import { modes } from '#/data/data'
import { useNavigate } from 'react-router-dom'
import styles from './ShopPage.module.css'
import { formatNumber } from '#/services/helpers'
import BoosterCard from './components/BoosterCard'
import Notification from '#/components/alerts/Notification'
import useNotification from '#/hooks/useNotification'

function ShopPage() {
  const [selectedBoosters, setSelectedBoosters] = useState([
    { id: 0, selected: false },
    { id: 1, selected: false },
  ])
  const [loading, setLoading] = useState(false)  // New loading state
  const { notification, isVisible, showNotification } = useNotification()

  const handleSelectBooster = (id) => {
    setSelectedBoosters((prevBoosters) => {
      if (Array.isArray(prevBoosters)) {
        return prevBoosters.map((booster) =>
          booster.id === id ? { ...booster, selected: !booster.selected } : booster
        )
      } else {
        console.error('selectedBoosters is not an array:', prevBoosters)
        return prevBoosters
      }
    })
  }
  // Context
  const { user, cards, boosters, buyBooster, buyVideocard } = useUser()

  // Selected videocard
  const [modeIndex, setModeIndex] = useState(0)

  // Items count manipulate
  const [itemsCount, setItemsCount] = useState(1)

  const handleAddCount = useCallback(() => {
    const haveBooster = user && user.boosters.find((item) => item.item_id === 0)
    if (haveBooster || selectedBoosters[0].selected) {

        setItemsCount((prevCount) => prevCount + 1)
      
    } else {
      return showNotification('error', 'To buy more than one card, purchase a booster "Multiple GPU"', 3000)
    }
  }, [user, selectedBoosters, itemsCount])

  const handleMinusCount = useCallback(() => {
    setItemsCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount))
  }, [])

  useEffect(() => {
    if (!selectedBoosters[0].selected && !existingMultiGPU(user)) {
      setItemsCount(1)
    }
  }, [selectedBoosters])

  // Back button
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/')
  }

  useEffect(() => {
    TelegramAPI.ShowBackButton()
    TelegramAPI.OnBackButtonClick(handleBack)
  }, [])

  // Background style
  const blockStyle = useMemo(() => modes[modeIndex].shopBlockStyle, [modeIndex])

  // Calculate total price
  const totalCoinPrice = useMemo(() => {
    let totalBoostersPrice = 0
    boosters.map((item) => {
      if (selectedBoosters[item.id].selected) {
        totalBoostersPrice += boosters[item.id].priceCoin
      }
    })
    let summ = itemsCount * parseFloat(cards[modeIndex]?.priceCoin) + totalBoostersPrice

    return summ
  }, [itemsCount, boosters, selectedBoosters, cards, modeIndex])

  // Payment
  const handlePayment = async (type) => {
    if (loading) return  // Prevent further clicks if already loading

    if (type === 'coin') {
      const totalPrice = totalCoinPrice

      if (totalPrice > user?.user?.balance) {
        return showNotification('error', 'Insufficient balance for this purchase.', 3000)
      }

      try {
        setLoading(true)  // Start loading

        const boosterResponses = await Promise.all(
          selectedBoosters.map(async (item) => {
            if (item.selected) {
              const response = await buyBooster(item.id)
              handleSelectBooster(item.id)
              return response.success
            }
            return true
          })
        )

        const allBoostersPurchased = boosterResponses.every((response) => response)

        if (allBoostersPurchased) {
          const response = await buyVideocard(modeIndex, itemsCount)
          if (response.success) {
            showNotification('success', 'Successfully purchased.', 3000)
          } else {
            showNotification('error', response.message, 3000)
          }
        } else {
          showNotification('error', 'Error with booster purchase.', 3000)
        }
      } catch (error) {
        showNotification('error', 'An error occurred during the purchase process.', 3000)
      } finally {
        setTimeout(() => setLoading(false), 300)
      }
    }
  }

  const existingMultiGPU = (user) => user?.boosters.some((item) => item.item_id === 0)

  return (
    <div
      className={styles.container}
      style={{
        overflowY: 'scroll',
      }}>
      <Slider cards={cards} modeIndex={modeIndex} setModeIndex={setModeIndex} blockStyle={blockStyle} />

      <div className={styles.summaryInfo}>
      <div className={styles.countLine}>
        <div className={styles.count__header}>Quantity</div>

        <div className={styles.countController}>
          <div
            className={`${styles.countButton} ${styles.countButton_minus} ${
              (existingMultiGPU(user) || selectedBoosters[0].selected) && itemsCount > 1 && styles.active
            }`}
            onClick={handleMinusCount}>
            -
          </div>
          <div className={styles.count}>{itemsCount}</div>
          <div
            className={`${styles.countButton} ${
              (existingMultiGPU(user) || selectedBoosters[0].selected) && styles.active
            }`}
            onClick={handleAddCount}>
            +
          </div>
        </div>
      </div>
      <div className={`text_style_description`} style={{ margin: '6px 0 20px' }}>
        If you don't have the Multiple GPU booster, purchasing a new card will replace your previous one, and 50% of
        the cost of the old card will be automatically refunded to your balance. To be able to own multiple cards at
        once, get the Multiple GPU booster.
      </div>
    </div>

    <div className={styles.boostersContainer}>
      <div className={styles.count__header}>Permanent boosters</div>
      <div className={styles.boosters}>
        {boosters &&
          boosters.map((booster, index) => (
            <BoosterCard
              item={booster}
              key={index}
              selected={selectedBoosters[booster.id].selected}
              onSelect={handleSelectBooster}
            />
          ))}
      </div>
    </div>

      <div className={styles.paymentContainer}>
        <div className={styles.modalBalance}>Your balance {user && formatNumber(user?.user.balance)}</div>
        <div className={styles.payment}>
          <div
            className={styles.paymentButton_coin}
            onClick={() => handlePayment('coin')}
            style={totalCoinPrice > user?.user.balance ? { opacity: 0.4 } : {}}>
            {loading ? <span className={styles.spinner}></span> : <><BalanceCoinIcon className={styles.coin} /> Pay {formatNumber(totalCoinPrice)}</>}
          </div>
        </div>
      </div>

      <BottomNavigation activeTab="shop" />
      <Notification notification={notification} isVisible={isVisible} />
    </div>
  )
}

export default ShopPage



