import React, { useEffect, useState } from 'react'

import TelegramAPI from '#/telegram'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'

import styles from './HomePage.module.css'

import { modes } from '#/data/data'
import BalanceButton from '#/components/buttons/BalanceButton/BalanceButton'
import BalanceContainer from './components/BalanceContainer/BalanceContainer'
import { useNavigate } from 'react-router-dom'
import { useUser } from '#/context/userContext'
import InfoModal from './components/Boosters'
import InfoSetcion from './components/InfoSection/InfoSection'
import { ReloadIcon } from '#/assets/icons'
import Notification from '#/components/alerts/Notification'
import useNotification from '#/hooks/useNotification'
import HighestCard from './components/HighestCard'
import Withdraw from './components/Withdraw'
import DepositModal from './components/Deposit'

function HomePage() {
  const { user, boosters, cards, claimMiningRewards, continueMining, updateUserInfo } = useUser()
  const { notification, isVisible, showNotification } = useNotification()
  const navigate = useNavigate()
  const [modeIndex, setModeIndex] = useState(1)
  const [modalOpened, setModalOpened] = useState(false)

  const [showWithdraw, setShowWithdraw] = useState(false)
  const [showDeposit, setShowDeposit] = useState(false)

  const handleOpenModal = () => {
    TelegramAPI.Vibrate('heavy')
    setModalOpened(true)
  }

  const handleWithdraw = () => {
    TelegramAPI.Vibrate('heavy')
    setShowWithdraw(true)
  }

  const handleDeposit = () => {
    TelegramAPI.Vibrate('heavy')
    setShowDeposit(true)
  }

  useEffect(() => {
    // updateUserInfo()
  }, [])

  const closeModal = () => {
    setModalOpened(false)
  }

  const [blockStyle, setBlockStyle] = useState({
    // backdropFilter: 'blur(0px)',
  })

  useEffect(() => {
    TelegramAPI.OffBackButtonClick()
    TelegramAPI.HideBackButton()
  }, [])

  useEffect(() => {
    if (modalOpened) {
      TelegramAPI.ShowBackButton()
      TelegramAPI.OnBackButtonClick(() => setModalOpened(false))
    } else {
      TelegramAPI.OffBackButtonClick()
      TelegramAPI.HideBackButton()
    }
  }, [modalOpened])

  useEffect(() => {
    if (user) {
      setBlockStyle(modes[user?.card].shopBlockStyle)
    }
  }, [user?.card])

  const handleOpenShop = () => {
    navigate('/shop')
  }

  const handleClaimMining = async () => {
    if (user) {
      const result = await claimMiningRewards()
      if (result.success) {
        showNotification('success', result.message, 3000)
      } else {
        showNotification('error', result.message, 3000)
      }
    }
  }

  const handleContinueMining = async () => {
    TelegramAPI.Vibrate('heavy')
    const response = await continueMining()
    if (response.success) {
      showNotification('success', response.message, 3000)
    } else {
      showNotification('error', response.message, 3000)
    }
  }

  if (user?.success === false) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.error__icon}>404</div>
        <h1 className={styles.error__title}>Data loading error</h1>
        <p className={styles.error__description}>
          Please try starting the mini app using the keyboard from the chat with the bot.
        </p>
      </div>
    )
  }

  if (user && !user?.success && user?.user) {
    return (
      <div
        className={styles.container}
        style={{
          overflowY: 'scroll',
        }}>
        <BalanceContainer onClick={handleOpenModal} balance={user?.user?.balance} />

        <HighestCard
          cards={cards}
          modeIndex={modeIndex}
          setModeIndex={setModeIndex}
          blockStyle={blockStyle}
          onClick={user?.miningInfo?.miningActive ? () => {} : () => handleContinueMining()}
        />

        <div
          className={styles.miningStatus}
          onClick={user?.miningInfo?.miningActive ? () => {} : () => handleContinueMining()}>
          GPU status:{' '}
          <span className={`${styles.status} ${user?.miningInfo.miningActive ? styles.online : styles.offline}`}>
            {user?.miningInfo.miningActive ? (
              'online'
            ) : (
              <>
                offline <ReloadIcon width={8} height={8} />
              </>
            )}
          </span>
        </div>
        {!user?.miningInfo.miningActive && user?.cards.length > 0 && (
          <div className={styles.miningStatus__alert}>Click to continue mining</div>
        )}

        <div className={styles.balanceButtons}>
          <BalanceButton type={'deposit'} onClick={handleDeposit}>
            Deposit
          </BalanceButton>
          <BalanceButton type={'withdraw'} onClick={handleWithdraw}>
            Withdraw
          </BalanceButton>
        </div>

        <InfoSetcion handleClaim={handleClaimMining} handleOpenShop={handleOpenShop} />
        <BottomNavigation activeTab={'home'} />
        <Notification notification={notification} isVisible={isVisible} />
        {modalOpened && <InfoModal boosters={boosters} closeModal={closeModal} userCard={user?.card} />}

        <Withdraw isOpen={showWithdraw} setIsOpen={setShowWithdraw} />
        <DepositModal isOpen={showDeposit} setIsOpen={setShowDeposit} />
      </div>
    )
  }
}

export default HomePage
