import React, { useState, useMemo, useCallback } from 'react'
import { SERVER_LINK } from '#/data/serverLink'
import { formatNumber } from '#/services/helpers'
import { BalanceCoinIcon } from '#/assets/icons'
import styles from './style.module.css'

const UserCard = React.memo(({ item, filter }) => {
  const [loading, setLoading] = useState(true)

  const handleImageLoad = () => {
    setLoading(false)
  }

  const ghtToTHS = useCallback((number) => {
    return parseFloat(number) / 1000
  }, [])

  const displayValue = useMemo(() => {
    const balance = (
      <div className={styles.user__balance}>
        <BalanceCoinIcon width={14} height={14} /> {formatNumber(parseFloat(item.balance))}
      </div>
    )

    const ghs = (
      <div className={styles.user__ghs}>
        {ghtToTHS(item.total_ghs).toFixed(2)}
        <span>TH/s</span>
      </div>
    )

    return filter === 'balance' ? (
      <>
        {balance}
        {ghs}
      </>
    ) : (
      <>
        {ghs}
        {balance}
      </>
    )
  }, [filter, item.balance, item.total_ghs, ghtToTHS])

  return (
    <div className={styles.user}>
      <div className={styles.user__icon}>
        {item?.photo_id ? (
          <>
            {loading && (
              <div className={styles.spinnerContainer}>
                <div className={styles.spinner}></div>
              </div>
            )}
            <img
              src={`${SERVER_LINK}/user-photo/${item.photo_id}`}
              className={styles.image}
              alt={item.name}
              onLoad={handleImageLoad}
              loading="lazy"
            />
          </>
        ) : (
          <div className={styles.defaultAvatar}>{item?.name.slice(0, 1)}</div>
        )}
      </div>
      <div className={styles.user__info}>
        <div className={styles.user__name}>{item.name}</div>
        <div className={styles.user__dataLine}>{displayValue}</div>
      </div>
    </div>
  )
})

export default UserCard
